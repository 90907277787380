import "./switcher.css";

export class TrafficControl {
  constructor(map, onBeforeSwitch, onSwitch, onAfterSwitch) {
    this._container = null;
    this.map = map;
    this.onBeforeSwitch = onBeforeSwitch;
    this.onSwitch = onSwitch;
    this.onAfterSwitch = onAfterSwitch;
    this.style = {};
  }

  onAdd(map) {
    this._map = map;

    // Create the container for the control
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl traffic-control";
    this._container.style.alignItems = "center";
    this._container.style.padding = "10px";
    this._container.style.backgroundColor = "white";
    this._container.style.borderRadius = "4px";
    this._container.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";


    const heading = document.createElement("strong");
    heading.innerText = "Traffic";
    heading.style.marginRight = "10px";
    heading.style.color = "#000";
    this._container.appendChild(heading);

    // Add toggle switch
    const toggleSwitch = document.createElement("input");
    toggleSwitch.type = "checkbox";
    toggleSwitch.className = "traffic-toggle";
    toggleSwitch.style.marginRight = "10px";
    toggleSwitch.checked =
      JSON.parse(window.localStorage.getItem("trafficEnabled")) === "on"
        ? true
        : false;
    toggleSwitch.addEventListener("change", () => {
      this.onBeforeSwitch();
      if (toggleSwitch.checked) {
        this.onSwitch("on");
      } else {
        this.onSwitch("off");
      }

      setTimeout(() => {
        this.map.setStyle(this.style.style, { diff: false });
        this.onAfterSwitch();
      }, 500);
    });

    // Create the label container with "Fast" and "Slow" text and an image
    const labelContainer = document.createElement("div");
    labelContainer.style.display = "flex";
    labelContainer.style.alignItems = "center";

    // Fast text
    const fastText = document.createElement("span");
    fastText.innerText = "Fast";
    fastText.style.marginRight = "5px";
    fastText.style.color = "#000";

    // Traffic speed image
    const speedImage = document.createElement("img");
    speedImage.src = "/images/traffic-legend.png"; // Replace with the actual path to your image
    speedImage.style.width = "78px";
    speedImage.style.height = "7px";
    speedImage.style.margin = "0 5px";

    // Slow text
    const slowText = document.createElement("span");
    slowText.innerText = "Slow";
    slowText.style.marginLeft = "5px";
    slowText.style.color = "#000";

    // Append elements to label container
    labelContainer.appendChild(fastText);
    labelContainer.appendChild(speedImage);
    labelContainer.appendChild(slowText);

    // Append toggle and label container to main container
    this._container.appendChild(toggleSwitch);
    this._container.appendChild(labelContainer);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  updateStyles(updatedStyles, defaultStyle) {
    this.style = updatedStyles.find((s) => s.id === "googleRoad");
  }
}
