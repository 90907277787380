import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {
  IconButton,
  Tooltip,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import Battery60Icon from "@mui/icons-material/Battery60";
import BatteryCharging60Icon from "@mui/icons-material/BatteryCharging60";
import Battery20Icon from "@mui/icons-material/Battery20";
import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import { orange, green, red, blue } from "@mui/material/colors";
import useUserAttributes from "common/attributes/useUserAttributes";
import { map } from "map/core/MapView";
import { devicesActions, sessionActions, settingActions } from "store";
import _ from "lodash";
import {
  formatAlarm,
  formatBoolean,
  formatPercentage,
  formatStatus,
  getStatusColor,
} from "../common/util/formatter";
import { useTranslation } from "../common/components/LocalizationProvider";
import { mapIconKey, mapIcons } from "../map/core/preloadImages";
import { useAdministrator } from "../common/util/permissions";
import { ReactComponent as EngineIcon } from "../resources/images/data/engine.svg";
import { useAttributePreference } from "../common/util/preferences";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "25px",
    height: "25px",
    filter: "brightness(0) invert(1)",
  },
  batteryText: {
    fontSize: "0.75rem",
    fontWeight: "normal",
    lineHeight: "0.875rem",
  },
  positive: {
    color: "rgb(87, 199, 212)",
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  App: {
    maxHeight: "calc(100vh - 64px)",
    fontSize: "14px",
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      border: "1px solid #3f403f",
    },
    "& .MuiTableCell-root": {
      [theme.breakpoints.up("sm")]: {
        padding: "13px 0px 13px 21px !important",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "13px 0px 13px 21px !important",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "18px 10px !important",
      },
    },
  },
  orange: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  ignitionGreen: {
    color: green[500],
  },
  ignitionRed: {
    color: red[500],
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  inputLabelRoot: {
    fontSize: "18px",
    fontWeight: 600,
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      border: "none !important",
    },
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const driversObject = useSelector((state) => state.drivers.items);
  const drivers = Object.values(driversObject);
  const admin = useAdministrator();
  const userZoom = useAttributePreference("deviceZoom", 0);
  const deviceZoom = userZoom || 14;
  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);
  const driverUniqueId = position?.attributes?.driverUniqueId;
  const getDriverName = (val) => {
    let driver = _.find(drivers, function (obj) {
      return obj.uniqueId == val || obj.uniqueId == val.replace(/^0+/, "");
    });
    return !!driver?.name ? driver?.name : val;
  };

  const positions = useSelector((state) => state.session.positions);
  const mapFollow = useAttributePreference("mapFollow", false);
  const devicePrimary = useAttributePreference("devicePrimary", "name");
  const deviceSecondary = useAttributePreference("deviceSecondary", "");
  const handleStatus = (status, deviceId) => {
    const found_pos = positions[deviceId];
    if (found_pos) {
      if (status == "online" && found_pos.attributes.motion == true) {
        return <Avatar className={classes.blue}>M</Avatar>;
      }
      if (status == "online") {
        return <Avatar className={classes.green}>A</Avatar>;
      }
      if (status == "offline") {
        return <Avatar className={classes.red}>O</Avatar>;
      }
      return <Avatar className={classes.orange}>U</Avatar>;
    }
    return <Avatar className={classes.red}>O</Avatar>;
  };

  const handleRowClick = async (selectedDeviceId) => {
    const res = await fetch(`/api/devices/${selectedDeviceId}`);

    if (res.ok) {
      const data = await res.json();
      const attributes = data?.attributes;
      const phone = data?.phone;

      if (
        !!!attributes?.accessConfiguration &&
        !!phone &&
        phone.includes(",")
      ) {
        handlePhoneToAccessConfig(data);
      }

      const accessConfiguration = attributes?.accessConfiguration?.split(",");
      console.log(accessConfiguration);
      dispatch(settingActions.UpdateAttributes({ ...attributes }));
      if (accessConfiguration && accessConfiguration.length > 0) {
        dispatch(
          settingActions.SetisSetIgnitionVisible(accessConfiguration[0] === "1")
        );
        dispatch(
          settingActions.SetIsSpeedingAlarmVisible(
            accessConfiguration[1] === "1"
          )
        );
        dispatch(
          settingActions.SetIsSleepModeVisible(accessConfiguration[2] === "1")
        );
        dispatch(
          settingActions.SetisTempVisible(accessConfiguration[3] === "1")
        );
        dispatch(
          settingActions.SetisTempMapVisible(accessConfiguration[9] === "1")
        );
        dispatch(
          settingActions.SetisGroupVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.UpdateDriverVisible(accessConfiguration[4] === "1")
        );
        dispatch(
          settingActions.UpdateDriverBehaviourVisible(
            accessConfiguration[5] === "1"
          )
        );
        dispatch(
          settingActions.UpdateMaintenanceVisible(
            accessConfiguration[6] === "1"
          )
        );
        dispatch(
          settingActions.UpdateNotificationsVisible(
            accessConfiguration[7] === "1"
          )
        );
        dispatch(
          settingActions.UpdateGroupsVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.SetHoursEnabled(accessConfiguration[10] === "1")
        );
        dispatch(
          settingActions.SetisMileageVisible(accessConfiguration[11] === "1")
        );
        dispatch(
          settingActions.SetisPreferenceVisible(accessConfiguration[12] === "1")
        );
        dispatch(
          settingActions.SetisCalendarVisible(accessConfiguration[13] === "1")
        );
        dispatch(
          settingActions.SetisAssignedGroupVisible(
            accessConfiguration[14] === "1"
          )
        );
        dispatch(
          settingActions.SetIsTrafficBarVisible(accessConfiguration[15] === "1")
        );
      } else {
        dispatch(settingActions.SetisSetIgnitionVisible(false));
        dispatch(settingActions.SetIsSpeedingAlarmVisible(false));
        dispatch(settingActions.SetIsSleepModeVisible(false));
        dispatch(settingActions.SetisTempVisible(true));
        dispatch(settingActions.SetisTempMapVisible(false));
        dispatch(settingActions.SetisGroupVisible(false));
        dispatch(settingActions.UpdateDriverVisible(false));
        dispatch(settingActions.UpdateDriverBehaviourVisible(false));
        dispatch(settingActions.UpdateMaintenanceVisible(false));
        dispatch(settingActions.UpdateNotificationsVisible(false));
        dispatch(settingActions.UpdateGroupsVisible(false));
        dispatch(settingActions.SetHoursEnabled(false));
        dispatch(settingActions.SetisMileageVisible(false));
        dispatch(settingActions.SetisPreferenceVisible(false));
        dispatch(settingActions.SetisCalendarVisible(false));
        dispatch(settingActions.SetisAssignedGroupVisible(false));
        dispatch(settingActions.SetIsTrafficBarVisible(false));
      }

      const contact = data.contact?.split(",");
      if (contact && contact.length > 0) {
        dispatch(settingActions.UpdateIgnition(contact[0] === "1"));
        dispatch(settingActions.UpdateSleepMode(contact[2] !== "0"));
        dispatch(settingActions.UpdateSpeedingAlarm(contact[1]));
        dispatch(settingActions.UpdateTempAlarm(contact[3]));
      }
      dispatch(settingActions.UpdateGroupId(data?.groupId));
    }
  };

  const handlePhoneToAccessConfig = async (data) => {
    //const res = await fetch(`/api/devices/${selectedDeviceId}`);
    let accessConfiguration = data?.phone;
    console.log(data);

    fetch(`/api/devices/${selectedDeviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        attributes: {
          ...data?.attributes,
          accessConfiguration: accessConfiguration,
        },
        phone: null,
      }),
    });
  };

  useEffect(() => {
    if (selectedDeviceId) {
      map.setZoom(deviceZoom);
      dispatch(sessionActions.toggleTabs(true));
      dispatch(sessionActions.SelectTab(0));
      handleRowClick(selectedDeviceId);
      dispatch(settingActions.SetIsMapResetEnabled(true));
    }
  }, [selectedDeviceId]);

  const secondaryText = () => {
    let status;
    if (item.status === "online" || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }
    return (
      <>
        {deviceSecondary == "driverName" && !!driverUniqueId ? (
          <>{`${getDriverName(driverUniqueId)} • `}</>
        ) : null}
        {deviceSecondary &&
          item[deviceSecondary] &&
          `${item[deviceSecondary]} • `}
        <span className={classes[getStatusColor(item.status)]}>{status}</span>
      </>
    );
  };

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        style={{
          background: item.id == selectedDeviceId ? "#0d0d0d" : "initial",
        }}
        onClick={() => {
          if (!!position?.longitude && !!position?.latitude) {
            if (mapFollow) {
              map.setZoom(deviceZoom);
            } else {
              map.jumpTo({
                center: [position.longitude, position.latitude],
                zoom: deviceZoom,
              });
            }
          }

          dispatch(devicesActions.selectItem(item));
          dispatch(sessionActions.toggleTabs(true));
          dispatch(sessionActions.SelectTab(0));

          dispatch(settingActions.SetIsMapResetEnabled(true));
          dispatch(devicesActions.selectId(item.id));
        }}
        disabled={!admin && item.disabled}
      >
        <ListItemAvatar>{handleStatus(item.status, item.id)}</ListItemAvatar>
        <ListItemText
          primary={item[devicePrimary]}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {position && (
          <>
            {position.attributes.hasOwnProperty("alarm") && (
              <Tooltip
                title={`${t("eventAlarm")}: ${formatAlarm(
                  position.attributes.alarm,
                  t
                )}`}
              >
                <IconButton size="small">
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty("ignition") && (
              <Tooltip
                title={`${t("positionIgnition")}: ${formatBoolean(
                  position.attributes.ignition,
                  t
                )}`}
              >
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <EngineIcon
                      width={20}
                      height={20}
                      className={classes.ignitionGreen}
                    />
                  ) : (
                    <EngineIcon
                      width={20}
                      height={20}
                      className={classes.ignitionRed}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty("batteryLevel") && (
              <Tooltip
                title={`${t("positionBatteryLevel")}: ${formatPercentage(
                  position.attributes.batteryLevel
                )}`}
              >
                <IconButton size="small">
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge ? (
                      <BatteryChargingFullIcon
                        fontSize="small"
                        className={classes.positive}
                      />
                    ) : (
                      <BatteryFullIcon
                        fontSize="small"
                        className={classes.positive}
                      />
                    )
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge ? (
                      <BatteryCharging60Icon
                        fontSize="small"
                        className={classes.medium}
                      />
                    ) : (
                      <Battery60Icon
                        fontSize="small"
                        className={classes.medium}
                      />
                    )
                  ) : position.attributes.charge ? (
                    <BatteryCharging20Icon
                      fontSize="small"
                      className={classes.negative}
                    />
                  ) : (
                    <Battery20Icon
                      fontSize="small"
                      className={classes.negative}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;
